import React, { useEffect, useRef, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import { useViewportScroll, useMotionValue } from 'framer-motion'

import useUIContext from 'context/ui'
import ViewportEnter from 'components/core/ViewportEnter'

import { CardsLayoutTriggerTypes } from './CardsLayoutTypes'

const CardsLayoutTrigger = ({ immediateTrigger }: CardsLayoutTriggerTypes) => {
  const [width, height] = useWindowSize({ wait: 300 })
  const { scrollYProgress, scrollY } = useViewportScroll()

  const onYChange = useRef<() => void>()
  const setCardsLayoutMotionY = useUIContext(s => s.setCardsLayoutMotionY)
  const setIsCardsLayoutActive = useUIContext(s => s.setIsCardsLayoutActive)
  const isCardsLayoutActive = useUIContext(s => s.isCardsLayoutActive)
  const isCardsLayoutFullyVisible = useUIContext(s => s.isCardsLayoutFullyVisible)
  const setIsCardsLayoutFullyVisible = useUIContext(s => s.setIsCardsLayoutFullyVisible)

  const ref = useRef<HTMLDivElement>(null)
  const yProgress = useMotionValue(0)

  const [viewState, setViewState] = useState('')

  const yThreshold = useRef(0.5)

  const onEnter = () => setViewState('entered')
  const onExit = () => setViewState('exited')

  useEffect(() => {
    yThreshold.current = width > 1024 ? 0.1 : 0.5
  }, [width, height])

  // Determine if cards layout is currently in view
  useEffect(() => {
    if (!ref.current || immediateTrigger) return
    if (scrollYProgress.get() >= 1) {
      setIsCardsLayoutActive(true)
    } else {
      setIsCardsLayoutActive(false)
      onYChange.current && onYChange?.current()
    }
  }, [viewState, scrollYProgress, ref, width, height, immediateTrigger, setIsCardsLayoutActive])

  // Scrolling progress coefficient (1 to 0) when cards come into view
  useEffect(() => {
    if (!isCardsLayoutActive || typeof document === undefined) return
    onYChange.current = scrollY.onChange(y => {
      yProgress.set(Math.max(Math.min((document.documentElement.scrollHeight - height - y) / height, 1), 0))
      setCardsLayoutMotionY(yProgress)
    })
    return onYChange.current
  }, [height, scrollY, isCardsLayoutActive, yProgress, setCardsLayoutMotionY])

  // Determine if cards layout is fully in view
  useEffect(() => {
    yProgress.onChange(y => {
      if (y > yThreshold.current) {
        isCardsLayoutFullyVisible && setIsCardsLayoutFullyVisible(false)
      } else {
        !isCardsLayoutFullyVisible && setIsCardsLayoutFullyVisible(true)
      }
    })
  }, [isCardsLayoutFullyVisible, setIsCardsLayoutFullyVisible, yProgress])

  // Set cards layout to be in view regardless of scroll position
  useEffect(() => {
    if (!immediateTrigger) return
    setIsCardsLayoutActive(true)
    setIsCardsLayoutFullyVisible(true)

    return () => {
      setIsCardsLayoutActive(false)
      setIsCardsLayoutFullyVisible(false)
    }
  }, [immediateTrigger, setIsCardsLayoutActive, setIsCardsLayoutFullyVisible])

  return (
    <ViewportEnter
      onEnter={!immediateTrigger ? onEnter : undefined}
      onExit={!immediateTrigger ? onExit : undefined}
      once={false}
    >
      <div ref={ref} />
    </ViewportEnter>
  )
}

export default CardsLayoutTrigger
