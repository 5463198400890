import React, { useMemo } from 'react'

import SEO from 'components/core/SEO'
import CardsLayoutTrigger from 'components/ui/CardsLayout/CardsLayoutTrigger'
import useTheme from 'lib/useTheme'
import useUIContext from 'context/ui'

import { HomePageTypes } from './HomePageTypes'

const HomePage = ({ data }: HomePageTypes) => {
  const isInitialLoad = useUIContext(s => s.isInitialLoad)
  const displayIntro = useMemo(() => isInitialLoad && process.env.NODE_ENV !== 'development', [isInitialLoad])

  useTheme('dark', 'white')

  if (!data) return null
  const document = data.prismicHomepage
  return (
    <>
      <SEO
        title={document.data.page_meta_title}
        description={document.data.page_meta_description}
        thumbnail={document.data.page_meta_thumbnail}
      />
      <CardsLayoutTrigger immediateTrigger={!displayIntro} />
    </>
  )
}

export default HomePage
