import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic/linkResolver'
import HomePage from 'views/HomePage'

import { prismicRepo } from '../../prismic-configuration'

export const query = graphql`
  query HomepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
      }
    }
    prismicSiteSettings(lang: { eq: $lang }) {
      lang
    }
  }
`

export default withPrismicPreview(HomePage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
  },
])
